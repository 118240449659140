import React from "react";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Text } from "../../elements/Text";

const RefundPolicyPage = () => {
  return (
    <Flex
      py={4}
      px={3}
      width="100%"
      maxWidth="600px"
      flexDirection="column"
      margin="0 auto"
    >
      <Heading textAlign="center">Refund Policy</Heading>

      <Text fontWeight="600">Returns</Text>
      <Text mb={3}>
        Our policy lasts 30 days. If 30 days have gone by since your purchase,
        unfortunately we can’t offer you a refund or exchange.
      </Text>

      <Text mb={3}>
        To be eligible for a return, your item must be unused and in the same
        condition that you received it. It must also be in the original
        packaging.
      </Text>

      <Text mb={3}>
        Several types of goods are exempt from being returned. Perishable goods
        such as food, flowers, newspapers or magazines cannot be returned. We
        also do not accept products that are intimate or sanitary goods,
        hazardous materials, or flammable liquids or gases.
      </Text>

      <Text>Additional non-returnable items:</Text>
      <Text>- Gift cards</Text>
      <Text>- Downloadable software products</Text>
      <Text mb={3}>- Some health and personal care items</Text>

      <Text>
        To complete your return, we require a receipt or proof of purchase.
      </Text>
      <Text mb={3}>
        Please do not send your purchase back to the manufacturer.
      </Text>

      <Text>
        There are certain situations where only partial refunds are granted (if
        applicable)
      </Text>
      <Text>
        - Any item not in its original condition, is damaged or missing parts
        for reasons not due to our error
      </Text>
      <Text mb={3}>
        - Any item that is returned more than 30 days after delivery
      </Text>

      <Text fontWeight="600">Refunds (if applicable)</Text>
      <Text>
        Once your return is received and inspected, we will send you an email to
        notify you that we have received your returned item. We will also notify
        you of the approval or rejection of your refund.
      </Text>
      <Text mb={3}>
        If you are approved, then your refund will be processed, and a credit
        will automatically be applied to your credit card or original method of
        payment, within a certain amount of days.
      </Text>

      <Text fontWeight="600">Late or missing refunds (if applicable)</Text>
      <Text>
        If you haven’t received a refund yet, first check your bank account
        again.
      </Text>
      <Text>
        Then contact your credit card company, it may take some time before your
        refund is officially posted.
      </Text>
      <Text>
        Next contact your bank. There is often some processing time before a
        refund is posted.
      </Text>
      <Text mb={3}>
        If you’ve done all of this and you still have not received your refund
        yet, please contact us at contact@leanwithlilly.com.
      </Text>

      <Text fontWeight="600">Sale items (if applicable)</Text>
      <Text mb={3}>
        Only regular priced items may be refunded, unfortunately sale items
        cannot be refunded.
      </Text>

      <Text fontWeight="600">Exchanges (if applicable)</Text>
      <Text mb={3}>
        We only replace items if they are defective or damaged. If you need to
        exchange it for the same item, send us an email at
        contact@leanwithlilly.com and we will provide you further details.
      </Text>

      <Text fontWeight="600">Gifts</Text>
      <Text mb={3}>
        If the item was marked as a gift when purchased and shipped directly to
        you, you’ll receive a gift credit for the value of your return.
      </Text>

      <Text mb={3}>
        If the item was marked as a gift when purchased and shipped directly to
        you, you’ll receive a gift credit for the value of your return.
      </Text>

      <Text fontWeight="600">Shipping</Text>
      <Text mb={3}>
        To return your product, please send us an email at
        contact@leanwithlilly.com and we will provide you further details.
      </Text>
      <Text mb={3}>
        You will be responsible for paying for your own shipping costs for
        returning your item. Shipping costs are non-refundable. If you receive a
        refund, the cost of return shipping will be deducted from your refund.
      </Text>

      <Text mb={3}>
        Depending on where you live, the time it may take for your exchanged
        product to reach you, may vary.
      </Text>
      <Text mb={3}>
        If you are shipping an item over $75, you should consider using a
        trackable shipping service or purchasing shipping insurance. We don’t
        guarantee that we will receive your returned item.
      </Text>
    </Flex>
  );
};

export default RefundPolicyPage;
